<template>
  <div>
    <h2>Mi is a Házasság hete?</h2>
    <p>
      Közel két évtizede Angliából indult el ez a kezdeményezés, amely Valentin-nap környékén minden évben egy hétig a házasság és a család
      fontosságára kívánja irányítani a figyelmet. A házasság hetét – amelyhez hazai és nemzetközi szinten is <strong>számos ismert közéleti
      személyiség csatlakozott</strong> kifejezve elköteleződését a házasság, a család ügye iránt – mára <strong>4 kontinens 21 országában</strong>
      ünneplik. Hazánkban az országos eseménysorozatot 2008 óta rendezik meg a <strong>keresztény egyházak és civil szervezetek</strong> széles körű
      összefogásával, számtalan nagyváros, település, közösség részvételével. A Házasság Hete Szervezőbizottsága által koordinált központi
      programok fővédnöke Novák Katalin köztársasági elnök és férje, Veres István.</p>
    <p><i>
      „A házasság Isten csodálatos ajándéka és gondoskodása az ember számára, amelyben a felek megtapasztalhatják a <strong>feltétel nélküli
      szeretet, a hűség és az őszinte megbocsátás</strong> értékét egy nekik rendelt, de nem tökéletes társon keresztül. A házasságban egy
      férfi és egy nő saját elhatározásából elkötelezi magát, hogy egymás bátorítására, segítésére és védelmére törekszik egész életében.
      Ilyen módon a házasság <strong>felelősségteljes, biztonságos és meghitt közösséget jelent</strong> számukra, és áldásul szolgál életük
      minden más területén is. A házasság hete olyan széles körű összefogásra törekszik, mely gyakorlati <strong>példamutatással</strong>, 
      tudományos <strong>kutatások eredményeivel</strong>, bibliai és erkölcsi <strong>érvekkel</strong> támasztja alá a házasság örökkévaló 
      értékét. <strong>Bátorít minden</strong> olyan <strong>helyi kezdeményezést</strong>, amely <strong>hidat épít</strong> az adott közösség
      környezetében élő házaspárok és családok felé,  amely segíti életüket, boldogulásukat. <strong>Nyíltan beszél</strong> a házasság mindennapi
      valóságáról, örömeiről és feszültségeiről. Igyekszik bölcs és gyakorlati tanácsokkal ellátni a <strong>házasokat és házasulandókat</strong>,
      valamint a teljes helyreállás reményét ébren tartani azokban, <strong>akiknek a kapcsolata válságban van</strong>, illetve valamilyen ok 
      miatt megszakadt."
    </i></p>

    <p class="right-align">A Házasság Hete Országos Szervezőbizottsága</p>
  </div>
</template>

<script>
  export default {
    name: 'history'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
